

































import {
  formatBigNumberString,
  formatRay,
  formatRayPercentage,
  RAY,
  rayDiv,
  rayMul
} from "@/utils/helpers";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AssetSymbol, Contracts } from "@/utils/types";
import { ethers } from "ethers";
import {
  debtTokens,
  Risk,
  tokenDecimals,
  tokenSymbolsList
} from "@/utils/constants";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { State } from "vuex-class";
import { AsyncComputed } from "@/utils/AsyncComputed";
import { IInterestModel } from "@/contracts/IInterestModel";
import IInterestModelABI from "@/contracts/IInterestModel.json";
import { getTokenAddress, getTokenSymbol } from "@/utils/tokens";
import { Position } from "@/utils/Position";
import { PriceFetcher } from "@/utils/PriceFetcher";

@Component
export default class extends Vue {
  @Prop({ required: true }) position!: Position;

  @State provider!: ethers.providers.JsonRpcProvider;
  @State contracts!: Contracts;

  get poolTokens() {
    if (this.position.asset.toLowerCase() === "3crv") {
      return ["DAI", "USDC", "USDT"];
    }

    return "-";
  }

  get poolName() {
    if (this.position.asset.toLowerCase() === "3crv") {
      return "Curve 3Pool";
    }

    return "-";
  }

  @AsyncComputed({ default: ethers.BigNumber.from(1) })
  async formattedAmountInUsd() {
    const value = await this.positionValue();
    const valueAsString = ethers.utils.formatUnits(value, 27 + 18);

    return formatBigNumberString(valueAsString);
  }

  async positionValue() {
    const tokens = ethers.utils.parseUnits(
      this.position.amount,
      tokenDecimals[this.position.asset as AssetSymbol]
    );

    return (
      await new PriceFetcher(
        this.contracts,
        this.provider
      ).getPriceOfUnitInWeiAsRay(this.position.asset as AssetSymbol)
    ).mul(tokens);
  }

  async debtValue() {
    let debtValue = ethers.BigNumber.from(0);

    const addressToSymbol: { [key: string]: AssetSymbol } = {};
    for (const name in this.contracts) {
      if ((tokenSymbolsList as string[]).includes(name)) {
        addressToSymbol[
          this.contracts[name as AssetSymbol]
        ] = name as AssetSymbol;
      }
    }

    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider
    ) as ILeveragePool;

    for (const reserve in this.position.debt) {
      if (Object.prototype.hasOwnProperty.call(this.position.debt, reserve)) {
        const reserveData = await leveragePool.getReserveData(reserve);

        const underlyingAsset = addressToSymbol[reserveData.underlyingToken];

        const amount = ethers.utils.parseUnits(
          this.position.debt[reserve],
          tokenDecimals[underlyingAsset]
        );

        debtValue = debtValue.add(
          (
            await new PriceFetcher(
              this.contracts,
              this.provider
            ).getPriceOfUnitInWeiAsRay(underlyingAsset)
          ).mul(amount)
        );
      }
    }

    return debtValue;
  }

  @AsyncComputed({ default: ethers.BigNumber.from(1) })
  async leverageAmount() {
    const positionValue = await this.positionValue();
    const debtValue = await this.debtValue();

    if (debtValue.gte(positionValue)) {
      return ethers.BigNumber.from(0);
    }

    const depositedValue = positionValue.sub(debtValue);

    return rayDiv(positionValue, depositedValue);
  }

  @AsyncComputed({ watch: ["leverageAmount"] })
  async formattedLeverageMultipler() {
    const leverageAmount = this.leverageAmount;

    if (((leverageAmount as unknown) as ethers.BigNumber).eq(0)) {
      return "Infinity";
    }

    return formatRay((leverageAmount as unknown) as ethers.BigNumber, 2);
  }

  get maxLeverage() {
    // TODO: Unhardcode
    return ethers.BigNumber.from(100).mul(RAY);
  }

  @AsyncComputed()
  async safety() {
    const leverageAmount = (this.leverageAmount as unknown) as ethers.BigNumber;
    if (leverageAmount.eq(0)) {
      return Risk.LIQUIDATABLE;
    }

    const tollableLevel = ethers.BigNumber.from(150).mul(RAY);
    if (leverageAmount.gt(tollableLevel)) {
      return Risk.TOLLABLE;
    }

    if (leverageAmount.gt(this.maxLeverage)) {
      return Risk.AT_RISK;
    }

    return Risk.SAFE;
  }

  @AsyncComputed({ watch: ["safety"] })
  async formattedSafety() {
    switch ((this.safety as unknown) as Risk) {
      case Risk.LIQUIDATABLE:
        return "liquidatable";
      case Risk.TOLLABLE:
        return "tollable";
      case Risk.AT_RISK:
        return "at risk";
      case Risk.SAFE:
        return "safe";
    }
  }

  @AsyncComputed({ watch: ["leverageAmount"] })
  async leverageUtilization() {
    const utilization = rayDiv(
      (this.leverageAmount as unknown) as ethers.BigNumber,
      this.maxLeverage
    );

    return formatRayPercentage(utilization, 4);
  }

  @AsyncComputed()
  async suppliedInUsd() {
    const positionValue = await this.positionValue();
    const debtValue = await this.debtValue();

    // TODO: Fetch properly
    const usdToWei = ethers.BigNumber.from(10).pow(18);

    if (positionValue.lt(debtValue)) {
      return `-${formatRay(debtValue.sub(positionValue).div(usdToWei), 2)}`;
    }

    return formatRay(positionValue.sub(debtValue).div(usdToWei), 2);
  }

  get _underlyingApy() {
    // TODO: Fetch this properly
    // 7.563%
    return RAY.mul(7563).div(100000);
  }

  @AsyncComputed()
  async effectiveApy() {
    const underlyingApy = this._underlyingApy;

    const currentValue = await this.positionValue();
    console.log("currentValue", currentValue.toString());
    const valueAfterAYear = rayMul(currentValue, RAY.add(underlyingApy));

    let allDebtToRepayValue = ethers.BigNumber.from(0);

    const addressToSymbol: { [key: string]: AssetSymbol } = {};
    for (const name in this.contracts) {
      if ((tokenSymbolsList as string[]).includes(name)) {
        addressToSymbol[
          this.contracts[name as AssetSymbol]
        ] = name as AssetSymbol;
      }
    }

    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider
    ) as ILeveragePool;

    for (const reserve in this.position.debt) {
      if (Object.prototype.hasOwnProperty.call(this.position.debt, reserve)) {
        const debtAmount = this.position.debt[reserve];
        const reserveData = await leveragePool.getReserveData(reserve);

        const totalDebt = reserveData.cached.totalDebt;
        const totalSaving = reserveData.cached.totalSaving;
        const interestRateModelAddress = reserveData.interestModel;
        const underlyingAsset = reserveData.underlyingToken;

        const interestRateModel = new ethers.Contract(
          interestRateModelAddress,
          IInterestModelABI,
          this.provider
        ) as IInterestModel;

        const [, borrowRate] = await interestRateModel.getInterestRates(
          underlyingAsset,
          totalDebt,
          totalSaving
        );

        const underlyingTokenSymbol = getTokenSymbol(
          underlyingAsset,
          this.contracts
        ) as AssetSymbol;

        if (underlyingTokenSymbol === undefined) {
          throw new Error("Failed to get token symbol from address");
        }

        const debtToRepayInRay = RAY.add(borrowRate).mul(
          ethers.utils.parseUnits(
            debtAmount,
            tokenDecimals[underlyingTokenSymbol as AssetSymbol]
          )
        );

        const debtPrice = await new PriceFetcher(
          this.contracts,
          this.provider
        ).getPriceOfUnitInWeiAsRay(underlyingTokenSymbol);
        console.log("debtPrice", debtPrice.toString());
        const debtToRepayValue = rayMul(debtToRepayInRay, debtPrice);
        allDebtToRepayValue = allDebtToRepayValue.add(debtToRepayValue);
      }
    }

    const currentDebtValue = await this.debtValue();
    const suppliedValue = currentValue.sub(currentDebtValue);
    const finalValue = valueAfterAYear.sub(allDebtToRepayValue);

    const effectiveApy = rayDiv(finalValue, suppliedValue).sub(RAY);

    return effectiveApy;
  }

  @AsyncComputed({ watch: ["effectiveApy"] })
  async formattedEffectiveApy() {
    if (!this.effectiveApy) {
      return "-";
    }

    return formatRayPercentage(
      (this.effectiveApy as unknown) as ethers.BigNumber,
      2
    );
  }

  @AsyncComputed()
  async underlyingApy() {
    return formatRayPercentage(this._underlyingApy, 3);
  }

  @AsyncComputed({ watch: ["effectiveApy"] })
  async negativeApy() {
    if (!this.effectiveApy) {
      return false;
    }

    return ((this.effectiveApy as unknown) as ethers.BigNumber).lt(0);
  }

  @AsyncComputed({ watch: ["effectiveApy"] })
  async positiveApy() {
    if (!this.effectiveApy) {
      return false;
    }

    return ((this.effectiveApy as unknown) as ethers.BigNumber).gte(0);
  }
}
