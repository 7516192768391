













import { Component, Prop, Vue } from 'vue-property-decorator';
import FarmEntry from "@/components/farming/FarmEntry.vue";
import type { ActionMethod } from 'vuex';
import { State, Getter, Action } from 'vuex-class';
import { OpenPositionCall } from './OpenPositionModal.vue';
import { BigNumber, BigNumberish, ethers, Signer } from 'ethers';
import { CurveCatalyzer } from '@/contracts/CurveCatalyzer';
import CurveCatalyzerABI from "@/contracts/CurveCatalyzer.json";
import { Address, AssetMapping, AssetSymbol, Contracts, ReserveUnderlyingToken } from '@/utils/types';
import { ILeveragePool } from '@/contracts/ILeveragePool';
import LeveragePoolABI from "@/contracts/ILeveragePool.json";
import { IERC20 } from '@/contracts/IERC20';
import IERC20ABI from '@/contracts/IERC20.json';
import { tokenDecimals } from '@/utils/constants';
import { safeApprove } from '@/utils/tokens';

export type Farm = {
  name: string;
  poolTokens: AssetSymbol[];
  openPositionCall: OpenPositionCall;
}

@Component({
  components: {FarmEntry}
})
export default class extends Vue {
  @State contracts!: Contracts;
  @State signer!: Signer;
  @State address!: Address;

  private curve3PoolTokens: ReserveUnderlyingToken[] = ["DAI", "USDC", "USDT"];

  get farms(): Farm[] {
    return [
      {
        name: "Curve 3Pool",
        poolTokens: this.curve3PoolTokens,
        openPositionCall: async (
          amountsToProvide: AssetMapping<BigNumber>,
          reservesIndicesToUseForDebt: AssetMapping<number[]>,
          amountsToBorrowFromReserves: AssetMapping<BigNumber[]>
        ) => {
          console.log("amountsToProvide", amountsToProvide);
          console.log("reservesIndicesToUseForDebt", reservesIndicesToUseForDebt);
          console.log("amountsToBorrowFromReserves", amountsToBorrowFromReserves);

          for (const token of this.curve3PoolTokens) {
            const amountToProvide = amountsToProvide[token];

            if (amountToProvide.lte(0)) {
              continue;
            }

            const tokenContract = new ethers.Contract(
              this.contracts[token],
              IERC20ABI,
              this.signer,
            ) as IERC20;

            const allowance = await tokenContract.allowance(this.address, this.contracts.LeveragePool);

            if (allowance.lt(amountToProvide)) {
              throw new Error("Insufficiant allowance to open position");
            }
          }

          const curveCatalyzerAddress = this.contracts.CurveCatalyzer;
          const curveCatalyzer = new ethers.Contract(
            curveCatalyzerAddress,
            CurveCatalyzerABI,
            this.signer
          ) as CurveCatalyzer;

          const curveAssetBankAddress = this.contracts.CurveAssetBank;
          // TODO: Pass parameters for this
          const catalyzerCallData = curveCatalyzer.interface.encodeFunctionData(
            "addLiquidity",
            [
              this.contracts["3CRV"],
              [amountsToProvide.DAI, amountsToProvide.USDC, amountsToProvide.USDT],
              [reservesIndicesToUseForDebt.DAI, reservesIndicesToUseForDebt.USDC, reservesIndicesToUseForDebt.USDT],
              [amountsToBorrowFromReserves.DAI, amountsToBorrowFromReserves.USDC, amountsToBorrowFromReserves.USDT],
              0, // MinLP - TODO: this should be an advanced feature
              curveAssetBankAddress,
            ]
          );

          const leveragePoolAddress = this.contracts.LeveragePool;
          const leveragePool = new ethers.Contract(
            leveragePoolAddress,
            LeveragePoolABI,
            this.signer
          ) as ILeveragePool;

          const tx = await leveragePool
            .execute(
              ethers.constants.MaxUint256,
              curveCatalyzer.address,
              catalyzerCallData,
              // TODO: Might need to increase default gas limits...
              // Seems to fail every once in a while due to out of gas error.
            );

          return tx;
        }
      }
    ];
  }
}
