























































import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Getter, Action } from 'vuex-class';
import FlatButton from "@/components/FlatButton.vue";
import OpenPositionModal, { OpenPositionCall } from "@/components/farming/OpenPositionModal.vue";
import { AssetSymbol } from '@/utils/types';
import { Contract } from '@ethersproject/contracts';
import { ethers } from 'ethers';
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { ILeveragePool } from '@/contracts/ILeveragePool';
import { debtTokens } from '@/utils/constants';
import { AsyncComputed } from '@/utils/AsyncComputed';
import { IInterestModel } from '@/contracts/IInterestModel';
import IInterestModelABI from "@/contracts/IInterestModel.json";
import { RAY } from '@/utils/helpers';

@Component({
  components: { FlatButton, OpenPositionModal }
})
export default class extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) openPositionCall!: OpenPositionCall;
  @Prop({ required: true }) tokens!: AssetSymbol[];

  @State contracts!: Contract;
  @State provider!: ethers.providers.JsonRpcProvider;

  private levergeMultiplier = 100; // TODO: Unhardcode

  private _bestBorrowRate: ethers.BigNumber | undefined;

  get debtTokens(): string[] {
    const _debtTokens: string[] = [];

    for (const token of this.tokens) {
      _debtTokens.push.apply(_debtTokens, debtTokens[token]);
    }

    return _debtTokens;
  }

  private async bestDebtApy() {
    if (this._bestBorrowRate !== undefined) {
      return this._bestBorrowRate;
    }

    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider,
    ) as ILeveragePool;

    let bestBorrowRate = ethers.BigNumber.from(10).pow(1000);
    for (const token of this.tokens) {
      // NOTE: Assumes 1 reserve for each token
      const reserveAddress = (await leveragePool.getReserveForToken(this.contracts[token]))[0];
      const reserve = await leveragePool.getReserveData(reserveAddress);

      const totalDebt = reserve.cached.totalDebt;
      const totalSaving = reserve.cached.totalSaving;
      const interestRateModelAddress = reserve.interestModel;

      const interestRateModel = new ethers.Contract(
        interestRateModelAddress,
        IInterestModelABI,
        this.provider
      ) as IInterestModel;

      const underlyingAssetAddress = this.contracts[token];
      const [, borrowRate] = await interestRateModel.getInterestRates(underlyingAssetAddress, totalDebt, totalSaving);

      console.log("borrowRate", borrowRate.toString());

      if (borrowRate.lt(bestBorrowRate)) {
        bestBorrowRate = borrowRate;
      }
    }

    console.log("bestBorrowRate", bestBorrowRate.toString());

    this._bestBorrowRate = bestBorrowRate;
    return bestBorrowRate;
  }

  get farmPopupId(): string {
    return `${this.name}-open-position-modal`;
  }

  @AsyncComputed({ default: ethers.BigNumber.from(0) })
  async investmentApy() {
    // TODO: Get this properly
    return RAY.mul(7563).div(100000);
  }

  @AsyncComputed({ watch: ['levergeMultiplier', 'investmentApy'], default: "-" })
  async expectedAPYPercentage() {
    const investmentApy = this.investmentApy as unknown as ethers.BigNumber;
    const borrowCost = await this.bestDebtApy();

    const effectiveApy = (investmentApy.mul(this.levergeMultiplier)).sub(borrowCost.mul(this.levergeMultiplier - 1));

    const ray = ethers.BigNumber.from(10).pow(27);

    const percentage = effectiveApy.mul(100).div(ray);
    const rest = effectiveApy.mul(100).mod(ray);
    let formattedRest = '';
    for (let index = 0; index < 27 - rest.toString().length; index++) {
      formattedRest += '0';
    }
    formattedRest += rest.toString();
    formattedRest = formattedRest.slice(0, 3);

    return `${percentage.toString()}.${formattedRest}`;
  }
}
