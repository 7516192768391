














import { Component, Prop, Vue } from 'vue-property-decorator';
import FarmEntry from "@/components/farming/FarmEntry.vue";
import type { ActionMethod } from 'vuex';
import { State, Getter, Action } from 'vuex-class';
import { OpenPositionCall } from './OpenPositionModal.vue';
import { ethers, Signer } from 'ethers';
import { CurveCatalyzer } from '@/contracts/CurveCatalyzer';
import CurveCatalyzerABI from "@/contracts/CurveCatalyzer.json";
import { AssetMapping, AssetSymbol, Contracts } from '@/utils/types';
import { ILeveragePool } from '@/contracts/ILeveragePool';
import LeveragePoolABI from "@/contracts/ILeveragePool.json";
import { IERC20 } from '@/contracts/IERC20';
import IERC20ABI from '@/contracts/IERC20.json';
import { tokenDecimals } from '@/utils/constants';
import { formatBigNumberString } from '@/utils/helpers';
import PositionEntry from "@/components/farming/PositionEntry.vue";
import { Position } from '@/utils/Position';
import AccountButton from "@/components/AccountButton.vue";

export type Farm = {
  name: string;
  poolTokens: AssetSymbol[];
  openPositionCall: OpenPositionCall;
}

@Component({
  components: { PositionEntry, AccountButton }
})
export default class extends Vue {
  @State positions!: Position[];
  @State provider!: ethers.providers.Provider;
  @State address!: string;
  @State contracts!: Contracts;

  @Getter isConnected!: boolean;

  @Action setPositions!: ActionMethod;

  private refreshPositions() {
    this.setPositions({ provider: this.provider, address: this.address, contracts: this.contracts })
  }
}
