







































































































































































































































































import { ILeveragePool } from "@/contracts/ILeveragePool";
import {
  AssetMapping,
  AssetSymbol,
  Contracts,
  ReserveUnderlyingToken
} from "@/utils/types";
import { getIndexOfReserve } from "@/utils/reserves";
import { BigNumber, BigNumberish, ContractTransaction, ethers } from "ethers";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { tokenDecimals } from "@/utils/constants";
import { decimalToTokenBaseUnits } from "@/utils/tokens";
import ActionModal from "@/components/ActionModal.vue";
import { AsyncComputed } from "@/utils/AsyncComputed";
import { IInterestModel } from "@/contracts/IInterestModel";
import IInterestModelABI from "@/contracts/IInterestModel.json";
import { IERC20 } from "@/contracts/IERC20";
import IERC20ABI from "@/contracts/IERC20.json";

const defaultMap = {
  get: function() {
    return "-";
  }
};

export type OpenPositionCall = (
  amountsToProvide: AssetMapping<BigNumber>,
  reservesIndicesToUseForDebt: AssetMapping<number[]>,
  amountsToBorrowFromReserves: AssetMapping<BigNumber[]>
) => Promise<ContractTransaction>;

const DEFAULT_SUPPLY_FORM_VALUES = {
  DAI: "0",
  USDT: "0",
  USDC: "0",
  "3CRV": "0"
};

const DEFAULT_ENOUGH_ALLOWANCES = {
  DAI: false,
  USDT: false,
  USDC: false,
  "3CRV": false
};

const DEFAULT_PENDING_DEPOSITS = DEFAULT_ENOUGH_ALLOWANCES;

const DEFAULT_ALLOWANCES = DEFAULT_SUPPLY_FORM_VALUES;

const DEFAULT_DEBT_FORM_VALUES = {};

@Component({ components: { ActionModal } })
export default class extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) openPositionCall!: OpenPositionCall;
  @Prop({ required: true }) tokens!: AssetSymbol[];
  @Prop({ required: true }) levergeMultiplier!: number;

  @State contracts!: Contracts;
  @State provider!: ethers.providers.JsonRpcProvider;
  @State tokenBalances!: { [key: string]: number };
  @State address!: string;
  @State signer!: ethers.Signer;

  @Getter isConnected!: boolean;

  private internalLeverageMultiplier = this.levergeMultiplier;
  $modal: any;
  $toasted: any;

  private validatingOpenPositionCall = false;

  private formattedTokenBalance(token: string): number {
    return this.tokenBalances[token] ?? 0;
  }

  private formValues = {};
  private supplyAmount: { [key: string]: string } = Object.assign(
    {},
    DEFAULT_SUPPLY_FORM_VALUES
  );
  private debtAmount: { [key: string]: string } = Object.assign(
    {},
    DEFAULT_DEBT_FORM_VALUES
  );
  private enoughAllowanceFor = Object.assign({}, DEFAULT_ENOUGH_ALLOWANCES);
  private pendingDeposit = Object.assign({}, DEFAULT_PENDING_DEPOSITS);

  get provideModalId() {
    return this.id;
  }

  get allowanceModalId() {
    return `${this.id}-alowance`;
  }

  get debtModalId() {
    return `${this.id}-debt`;
  }

  get confirmModalId() {
    return `${this.id}-confirm`;
  }

  private hideAllModal() {
    (this.$refs.provideModal as Element).classList.remove("active");
    (this.$refs.allowanceModal as Element).classList.remove("active");
    (this.$refs.debtModal as Element).classList.remove("active");
    (this.$refs.confirmModal as Element).classList.remove("active");
  }

  get leverageUtilization() {
    let allocationTotal = 0;
    for (const token of this.tokens) {
      const allocation = parseFloat(
        this.debtAmount[`${token}-allocation`] ?? "0"
      );
      allocationTotal += allocation;
    }

    return allocationTotal.toFixed(2);
  }

  get leverageOverUtilized() {
    return parseFloat(this.leverageUtilization) > 100;
  }

  @AsyncComputed({ default: defaultMap })
  async debtApr() {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider
    ) as ILeveragePool;

    let rates: { [key: string]: any } = {};
    for (const token of this.tokens) {
      // NOTE: Assumes 1 reserve for each token
      const reserveAddress = (
        await leveragePool.getReserveForToken(this.contracts[token])
      )[0];
      const reserve = await leveragePool.getReserveData(reserveAddress);

      const totalDebt = reserve.cached.totalDebt;
      const totalSaving = reserve.cached.totalSaving;
      const interestRateModelAddress = reserve.interestModel;

      const interestRateModel = new ethers.Contract(
        interestRateModelAddress,
        IInterestModelABI,
        this.provider
      ) as IInterestModel;

      const underlyingAssetAddress = this.contracts[token];
      const [, borrowRate] = await interestRateModel.getInterestRates(
        underlyingAssetAddress,
        totalDebt,
        totalSaving
      );

      const ray = ethers.BigNumber.from(10).pow(27);

      const percentage = borrowRate.mul(100).div(ray);
      const rest = borrowRate.mul(100).mod(ray);
      let formattedRest = "";
      for (let index = 0; index < 27 - rest.toString().length; index++) {
        formattedRest += "0";
      }
      formattedRest += rest.toString();
      formattedRest = formattedRest.slice(0, 2);

      rates[token] = `${percentage.toString()}.${formattedRest}`;
    }

    return rates;
  }

  get effectiveLeverageMultipler() {
    let supplyValue = 0;
    let debtValue = 0;
    for (const token of this.tokens) {
      const price = 1; // TODO: Set properly
      supplyValue += parseFloat(this.supplyAmount[token]) * price;
      debtValue += parseFloat(this.debtAmount[token]) * price;
    }

    return ((supplyValue + debtValue) / supplyValue).toFixed(2);
  }

  private showChooseProvide() {
    this.$modal.show(this.provideModalId);
    this.hideAllModal();
    (this.$refs.provideModal as Element).classList.add("active");
  }

  private showAllowance() {
    this.$modal.show(this.allowanceModalId);
    this.hideAllModal();
    (this.$refs.allowanceModal as Element).classList.add("active");
  }

  private showChooseDebt() {
    let valuesSet = false;
    for (const asset in this.supplyAmount) {
      if (Object.prototype.hasOwnProperty.call(this.supplyAmount, asset)) {
        const suppliedAmount = parseFloat(this.supplyAmount[asset]);
        if (this.debtAmount[asset] !== undefined) {
          valuesSet = true;
        }
      }
    }

    if (this.internalLeverageMultiplier < 1) {
      this.internalLeverageMultiplier = this.levergeMultiplier;
    }

    if (!valuesSet) {
      for (const asset in this.supplyAmount) {
        if (Object.prototype.hasOwnProperty.call(this.supplyAmount, asset)) {
          const suppliedAmount = parseFloat(this.supplyAmount[asset]);
          this.$set(
            this.debtAmount,
            asset,
            (suppliedAmount * (this.internalLeverageMultiplier - 1)).toString()
          );

          console.log(
            suppliedAmount,
            "vs",
            (suppliedAmount * (this.internalLeverageMultiplier - 1)).toString()
          );
        }
      }

      this.updateAllocationsBasedOnAmounts();
    }

    this.$modal.show(this.debtModalId);
    this.hideAllModal();
    (this.$refs.debtModal as Element).classList.add("active");
  }

  private showConfirmation() {
    this.$modal.show(this.confirmModalId);
    this.hideAllModal();
    (this.$refs.confirmModal as Element).classList.add("active");
  }

  private open() {
    this.$modal.show(this.id);
    (this.$refs.provideModal as Element).classList.add("active");
    this.handleOpen();
  }

  private handleOpen() {
    (this.$refs.container as Element).classList.add("open");
  }

  private handleClose(event: any) {
    event.cancel();
    this.close();
  }

  private close() {
    this.hideAllModal();
    (this.$refs.container as Element).classList.remove("open");
    this.supplyAmount = Object.assign({}, DEFAULT_SUPPLY_FORM_VALUES);
    this.debtAmount = Object.assign({}, DEFAULT_DEBT_FORM_VALUES);
  }

  private validateSupply() {
    let value = 0;
    for (const asset in this.supplyAmount) {
      if (Object.prototype.hasOwnProperty.call(this.supplyAmount, asset)) {
        const amount = this.supplyAmount[asset];
        // TODO: Properly calculate value
        value += parseFloat(amount);
      }
    }

    // TODO: Unhardcode
    const minValue = 1000;

    if (value <= 0) {
      return {
        success: false,
        msg: "Some tokens must be supplied."
      };
    }

    // if (minValue > value) {
    //   return { success: false, msg: "" };
    // }

    return { success: true };
  }

  get allApproved() {
    console.log("Running all approved");
    for (const token of this.tokens) {
      const enoughAllowance = this.enoughAllowanceFor[token];

      if (!enoughAllowance) {
        console.log("NOT ENOUGH ALLOWANCE");
        return false;
      }
    }

    return true;
  }

  private async completeSupply() {
    const { success, msg } = this.validateSupply();
    (this.$refs.supplyErrorMsg as Element).innerHTML = "";

    if (!success) {
      (this.$refs.supplyErrorMsg as Element).innerHTML = msg ?? "";

      return;
    }

    let showAllowanceModal = false;
    for (const symbol of this.tokens) {
      const amount = ethers.utils.parseUnits(
        this.supplyAmount[symbol],
        tokenDecimals[symbol]
      );

      const token = new ethers.Contract(
        this.contracts[symbol],
        IERC20ABI,
        this.provider
      ) as IERC20;

      const allowance = await token.allowance(
        this.address,
        this.contracts.LeveragePool
      );

      // TODO: IMPORANT! TAKE INTO ACCOUNT PENDING TRANSACTIONS THAT WILL
      // USE THE ALLOWANCE. Or handle this case in some way.

      this.$set(this.enoughAllowanceFor, symbol, allowance.gte(amount));

      if (!this.enoughAllowanceFor[symbol]) {
        showAllowanceModal = true;
      }
    }

    if (showAllowanceModal) {
      console.log("Showing allowance");
      this.showAllowance();
      return;
    }

    this.showChooseDebt();
  }

  private validateDebt() {
    if (parseFloat(this.leverageUtilization) > 100) {
      return {
        success: false,
        msg:
          "Over utilized leverage. Either increase leverage or decrease allocations."
      };
    }

    return { success: true };
  }

  private completeAllowance() {
    if (!this.allApproved) {
      return;
    }

    this.showChooseDebt();
  }

  private completeDebt() {
    const { success, msg } = this.validateDebt();
    (this.$refs.debtErrorMsg as Element).innerHTML = "";

    if (!success) {
      (this.$refs.debtErrorMsg as Element).innerHTML = msg ?? "";

      return;
    }

    this.showConfirmation();
  }

  private updateAmountsBasedOnAllocationsAndLeverage() {
    for (const token of this.tokens) {
      const allocation =
        parseFloat(this.debtAmount[`${token}-allocation`] ?? "0") / 100;

      let totalValue = 0; // TODO: Set properly
      for (const token of this.tokens) {
        totalValue +=
          parseFloat(this.supplyAmount[token]) *
          parseFloat((this.internalLeverageMultiplier - 1).toString());
      }
      const price = 1; // TODO: Set properly

      const newValue = totalValue * allocation;
      const newAmount = newValue * price;
      const newAmountDecimals = (newAmount.toString().split(".")[1] ?? "")
        .length;
      this.$set(
        this.debtAmount,
        token,
        newAmount.toFixed(Math.min(newAmountDecimals, tokenDecimals[token]))
      );
    }
  }

  private updateAllocationsBasedOnAmounts() {
    let totalValue = 0;

    let supplyValue = 0;

    console.log("HERE!");

    const values: AssetMapping<number> = {
      DAI: 0,
      USDT: 0,
      USDC: 0,
      "3CRV": 0
    };
    for (const token of this.tokens) {
      const amount = parseFloat(this.debtAmount[token] ?? "0");
      const price = 1; // TODO: Set properly
      const value = amount * price;
      values[token] = value;

      const tokenSupplyValue =
        parseFloat(this.supplyAmount[token] ?? "0") * price;
      supplyValue += tokenSupplyValue;
    }

    const maxBorrowValue = supplyValue * (this.internalLeverageMultiplier - 1);

    for (const token of this.tokens) {
      const allocation = (values[token] * 100) / maxBorrowValue;

      this.$set(this.debtAmount, `${token}-allocation`, allocation.toFixed(2));
    }
  }

  // @Watch("internalLeverageMultiplier")
  // private handleLeverageChange(newLeverage: string, oldLeverage: string) {
  //   // Update amounts based on allocations
  //   this.updateAmountsBasedOnAllocationsAndLeverage();
  // }

  @Watch("debtAmount", { deep: true })
  private handleDebtFormUpdate(
    newFormValues: { [x: string]: any },
    oldFormValues: { [x: string]: any }
  ) {
    if (!(this.$refs.container as Element).classList.contains("open")) {
      return;
    }

    let updateAmounts = false;
    let updateAllocations = false;

    if (newFormValues.leverageMultipler !== oldFormValues.leverageMultipler) {
      updateAmounts = true;
    }

    for (const token of this.tokens) {
      if (
        newFormValues[`${token}-allocation`] !==
        oldFormValues[`${token}-allocation`]
      ) {
        updateAmounts = true;
      }

      if (newFormValues[token] !== oldFormValues[token]) {
        updateAllocations = true;
      }
    }

    if (updateAmounts && updateAllocations) {
      console.error("Both updateAmounts and updateAllocations are set");
    }

    if (updateAmounts) {
      console.log("Updating amounts");
      this.updateAmountsBasedOnAllocationsAndLeverage();
    }

    if (updateAllocations) {
      console.log("Updating allocation");
      this.updateAllocationsBasedOnAmounts();
    }
  }

  private async openPosition() {
    const debtReservesMapping: AssetMapping<number[]> = {
      DAI: [],
      USDC: [],
      USDT: [],
      "3CRV": []
    };

    const debtAmountMapping: AssetMapping<BigNumber[]> = {
      DAI: [],
      USDC: [],
      USDT: [],
      "3CRV": []
    };

    const leveragePool = new ethers.Contract(
      this.contracts.LeveragePool,
      ILeveragePoolABI,
      this.provider
    ) as ILeveragePool;

    for (const token of this.tokens) {
      if (Object.prototype.hasOwnProperty.call(this.debtAmount, token)) {
        const amount = decimalToTokenBaseUnits(token, this.debtAmount[token]);

        if (amount.lte(0)) {
          continue;
        }

        // const reserves = await leveragePool.getReserveForToken(
        //   this.contracts[token]
        // );

        // NOTE: Assumes only 1 reserve
        const reserveIndex = 0;
        debtReservesMapping[token].push(reserveIndex);
        debtAmountMapping[token].push(amount);
      }
    }

    const supplyAmount: AssetMapping<BigNumber> = {
      DAI: BigNumber.from(0),
      USDC: BigNumber.from(0),
      USDT: BigNumber.from(0),
      "3CRV": BigNumber.from(0)
    };

    for (const token of this.tokens) {
      if (Object.prototype.hasOwnProperty.call(this.supplyAmount, token)) {
        const amount = decimalToTokenBaseUnits(token, this.supplyAmount[token]);
        supplyAmount[token] = amount;
      }
    }

    this.validatingOpenPositionCall = true;

    let tx;
    try {
      tx = await this.openPositionCall(
        supplyAmount,
        debtReservesMapping,
        debtAmountMapping
      );
    } catch (e) {
      console.error(e);
      // TODO: Handle properly
      this.validatingOpenPositionCall = false;
      return;
    }

    this.validatingOpenPositionCall = false;

    this.close();

    const toast = this.$toasted.info(
      "Open position transaction submitted! Awaiting confirmation...",
      {
        action: {
          text: "Dismiss",
          onClick: (e: any, toastObject: any) => {
            toastObject.goAway(0);
          }
        }
      }
    );

    // TODO: This should be synced up with a backend server (or maybe local storage is enough?)
    // so that users can get this notification even when the go offline.
    await tx.wait(1 /* confirmations */);
    toast.goAway(0);
    // Tx has been mined
    this.$toasted.success(
      `Transaction confirmed! Your position has been opened!`,
      {
        action: {
          text: "Dismiss",
          onClick: (e: any, toastObject: any) => {
            toastObject.goAway(0);
          }
        }
      }
    );
  }

  async approve(symbol: AssetSymbol) {
    // TODO: Figure out how to take into account pending transactions that will
    // use the balance.
    const amount = ethers.utils.parseUnits(
      this.supplyAmount[symbol],
      tokenDecimals[symbol]
    );

    const token = new ethers.Contract(
      this.contracts[symbol],
      IERC20ABI,
      this.signer
    ) as IERC20;

    // TODO: Handle pending approves
    // Create a nice system to handle all pending transactions and store them with potential metadata

    try {
      const tx = await token.approve(this.contracts.LeveragePool, amount);
      this.$set(this.pendingDeposit, symbol, true);
      await tx.wait(1);
    } catch (e) {
      if (e.code === 4001) {
        // Rejected by user;
        this.$set(this.pendingDeposit, symbol, false);
      } else {
        throw e;
      }
    }

    this.$set(this.pendingDeposit, symbol, false);

    const allowance = await token.allowance(
      this.address,
      this.contracts.LeveragePool
    );

    // TODO: IMPORANT! TAKE INTO ACCOUNT PENDING TRANSACTIONS THAT WILL
    // USE THE ALLOWANCE. Or handle this case in some way.
    this.$set(this.enoughAllowanceFor, symbol, allowance.gte(amount));

    console.log(
      "set this.enoughAllowanceFor to",
      this.enoughAllowanceFor[symbol]
    );
  }
}
