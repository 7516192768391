












import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import FarmsList from "@/components/farming/FarmsList.vue";
import PositionsList from "@/components/farming/PositionsList.vue";

@Component({
  components: { PageHeader, FarmsList, PositionsList }
})
export default class Farm extends Vue {}
