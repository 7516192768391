





















import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation } from "vuex-class";
import {
  baseTokenUnitsToDecimal,
  decimalToTokenBaseUnits,
  safeApprove
} from "@/utils/tokens";
import { AssetMapping, AssetSymbol, Contracts } from "@/utils/types";
import { ContractTransaction, ethers, Transaction } from "ethers";
import IERC20ABI from "@/contracts/IERC20.json";
import { IERC20 } from "@/contracts/IERC20";
import OverlyingTokenABI from "@/contracts/OverlyingToken.json";
import { OverlyingToken } from "@/contracts/OverlyingToken";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import AccountButton from "@/components/AccountButton.vue";
import { ActionMethod, MutationMethod } from "vuex";
import { AsyncComputed } from "@/utils/AsyncComputed";
import FlatButton from "@/components/FlatButton.vue";

@Component({
  components: { AccountButton, FlatButton }
})
export default class extends Vue {
  @Prop({ required: true }) readonly id!: string;
  @Prop({ default: 300 }) readonly height!: number;
  @Prop({ default: 300 }) readonly width!: number;
  @Prop({ default: () => {} }) readonly opened!: () => {};
  @Prop({ default: () => {} }) readonly closed!: () => {};
  @Prop({ default: () => {} }) readonly beforeClose!: () => {};

  @Getter isConnected!: boolean;
  $modal: any;

  close() {
    this.$modal.hide(this.id);
  }
}
